<template>
  <TunnelLayout>
    <template #form>
      <div class="flex flex-col">
        <h2 class="font-bold text-3xl mb-4 text-promy-blue-300 sm:text-base">
          Apprenons à nous connaître. <br />
          Qui êtes vous ?
        </h2>
        <div class="flex items-baseline">
          <div class="w-full pb-8">
            <div>
              <validation-provider
                class="relative flex flex-col"
                rules="required"
                v-slot="{ errors }"
              >
                <div class="grid gap-2 grid-template-step4">
                  <selectable-button
                    v-for="(type, index) in userTypes"
                    :key="type.name + index"
                    v-model="form.informations.label_profile"
                    :option-name="type.name"
                    @onUpdateOption="change"
                  >
                    <template #icon>
                      <ProIcon
                        class="!h-12 !w-12"
                        :name="type.icon"
                        :color="'promy-blue-200'"
                      />
                    </template>
                  </selectable-button>
                </div>
                <span
                  v-show="errors[0]"
                  class="mt-1 text-xs text-promy-red-400"
                  >{{ errors[0] }}</span
                >
              </validation-provider>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template #buttons>
      <Buttons :form="form" :goNext="invalid" />
    </template>
    <template slot="right-side">
      <Map :showBtnParcelle="false" :form="form" :cadastre="cadastre" />
    </template>
  </TunnelLayout>
</template>
<script>
import Buttons from './components/Buttons.vue'
import Map from './components/Map.vue'

export default {
  components: {
    Buttons,
    Map,
  },
  props: {
    form: {
      type: Object,
      required: true,
    },
    cadastre: {
      type: Object,
      require: true,
    },
    invalid: Boolean,
  },
  data() {
    return {
      userTypes: [
        {
          name: 'propriétaire',
          icon: 'owner',
        },
        {
          name: 'un proche',
          icon: 'proche',
        },
        {
          name: 'locataire',
          icon: 'tenant',
        },
        {
          name: 'professionnel',
          icon: 'professional',
        },
      ],
    }
  },

  methods: {
    change(value) {
      this.form.informations.label_profile = value
    },
  },
}
</script>
<style lang="scss" scoped>
.grid-template-step4 {
  grid-template-columns: repeat(auto-fill, 133px);
}
</style>
